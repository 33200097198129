textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 10px #f7a810;
  transition: border linear 0.2s, box-shadow linear 0.2s;
}
@media only screen and (max-width: 400px) {
  .g-recaptcha {
    transform: scale(0.77);
    transform-origin: 0 0;
  }
}
.form-format input[type="text"],
.form-format input[type="password"],
.form-format input[type="datetime"],
.form-format input[type="datetime-local"],
.form-format input[type="date"],
.form-format input[type="month"],
.form-format input[type="time"],
.form-format input[type="week"],
.form-format input[type="number"],
.form-format input[type="email"],
.form-format input[type="url"],
.form-format input[type="search"],
.form-format input[type="tel"],
.form-format input[type="color"] {
  text-indent: 5px;
  background-color: #ffffff;
  border: solid 1px #bebebe;
  /*&.button {
      min-width: inherit;
    }*/
}
.form-format textarea {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 150px;
  min-height: 150px;
  margin-top: 5px;
  text-indent: 5px;
  background-color: #ffffff;
  border: solid 1px #bebebe;
}
.form-format textarea.full {
  width: 100%;
}
/*END FORMS*/
/*# sourceMappingURL=css/form-format.css.map */